import { Link } from "react-router-dom";
import "../Styles/Work.scss";
import Techlab from "../Images/ProjectScreenshots/techlabfigma.png";
import Portfolio from "../Images/ProjectScreenshots/portfolio.png";
import Roadtrip from "../Images/ProjectScreenshots/roadtrip.png";
import LoremIpsum from "../Images/ProjectScreenshots/loremipsum.png";
import Etsy from "../Images/ProjectScreenshots/etsy.png";
import Tag from "./Tags";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "../Styles/Tabs.scss";

function Projects() {
  return (
    <div className="projects-container" name="Projects" id="projects">
      <h2>Projects</h2>
      <Tabs>
        <TabList>
          <Tab>TechLab</Tab>
          <Tab>Road Eats</Tab>
          <Tab>Etsy Storefront</Tab>
          <Tab>Portfolio </Tab>
          <Tab>Golden Ipsum</Tab>
        </TabList>

        <TabPanel>
          <div className="techlab-example project">
            <div className="project-info">
              <h3>thetechlab.ca</h3>
              <div className="tags">
                <Tag text="React" />
                <Tag text="Sass" />
                <Tag text="HTML" />
              </div>
              <p>
                This React website was created for a local client that wanted a
                simple but sleek way to display their online services. Several
                components were built in order to provide information about
                services, the company, and allow inquiries to be routed directly
                to the HR inbox utilizing EmailJS.
              </p>
            </div>

            <a
              href="https://thetechlab.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Techlab}
                alt="screenshot of website"
                className="techlab"
              />
            </a>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="road-trip-example project">
            <div className="project-info">
              <h3>Road Trip Eats</h3>
              <div className="tags">
                <Tag text="Rest API" />
                <Tag text="Node.js" />
                <Tag text="HTML" />
                <Tag text="CSS" />
                <Tag text="JavaScript" />
              </div>
              <p>
                This website utilizes the Google Sheets API. I utilized node.js
                to set up an Express server. Once authenticated, the app is able
                to access the data from a specified spreadsheet. This
                application would be ideal for a client who does not want to
                invest in a CMS, but would like the freedom to add and change
                article items themselves.
              </p>
            </div>
            <a
              href="https://lauramariganello.com/googleSheetsMenu/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Roadtrip}
                alt="screenshot of website"
                className="roadtrip"
              ></img>
            </a>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="etsy-example project">
            <div className="project-info">
              <h3>Etsy Storefront</h3>
              <div className="tags">
                <Tag text="React" />
                <Tag text="Sass" />
                <Tag text="HTML" />
              </div>
              <p>
                Leveraging React.JS to create a template for Etsy Sellers to
                utilize to create a more broad online presence. Through a
                strategic component-based approach, I can seamlessly replicate
                and adapt this template for various clients, streamlining the
                process of creating multiple websites. This product allows for a
                simple effective online presence that can assist with SEO
                ranking.
              </p>
              {/* <div className="centered-button">
                <button>
                  <a href="https://thetechlab.ca/" target="_blank">
                    Check it out!
                  </a>
                </button>
              </div> */}
            </div>
            <Link to="/etsy-template">
              <img
                src={Etsy}
                alt="screenshot of website"
                className="etsy"
              ></img>
            </Link>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="portfolio-example project">
            <div className="project-info">
              <h3>lauramariganello.ca</h3>
              <div className="tags">
                <Tag text="React" />
                <Tag text="Sass" />
                <Tag text="HTML" />
              </div>
              <p id="portfolio">
                I created my portfolio with the React.JS library to ensure I can
                update it seemlessly with every project and opportunity I
                undertake. The simplicity of adding new individual project
                components as well as the availabilitly of library components
                for timeline and tab features make this portfolio template
                adaptable and interactive.
              </p>
              {/* <div className="centered-button">
                <button>
                  <a href="#hero">You're here!</a>
                </button>
              </div> */}
            </div>
            <a
              href="https://lauramariganello.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Portfolio}
                alt="screenshot of website"
                className="portfolio"
              ></img>
            </a>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="lorem-ipsum-example project">
            <div className="project-info">
              <h3>Lorem Ipsum Generator</h3>
              <div className="tags">
                <Tag text="HTML" />
                <Tag text="CSS" />
                <Tag text="JavaScript" />
              </div>
              <p>
                This generator allows the user to generate an alternative to
                lorem ipsum, that just so happens to provide summaries of Golden
                Girls episodes instead of latin! JavaScript takes a submitted
                value and uses the array 'slice' method to insert the requested
                number of paragraphs into the page.
              </p>
              {/* <div className="centered-button">
                <button>
                  <a
                    href="https://lauramariganello.com/lauraIpsumGen/index.html"
                    target="_blank"
                  >
                    Check it out!
                  </a>
                </button>
              </div> */}
            </div>
            <a
              href="https://lauramariganello.com/lauraIpsumGen/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={LoremIpsum}
                alt="screenshot of website"
                className="lorem-ipsum"
              ></img>
            </a>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}

export default function Work() {
  return (
    <div className="work-container">
      <Projects />
    </div>
  );
}
