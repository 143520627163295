import "../Styles/Hero_Etsy.scss";

export default function Hero_Etsy() {
  return (
    <div className="etsy-hero-container">
      <div className="etsy-hero-text">
        <h1>
          Your <br></br>Storename<br></br>Here
        </h1>
      </div>
    </div>
  );
}
