export default function Tag({ text }) {
  const tagStyle = {
    padding: "2px 4px",
    display: "inline-block",
  };

  return (
    <span className="skill-tag" style={tagStyle}>
      {text}
    </span>
  );
}
