import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "../Styles/Experience.scss";
import { ReactComponent as WorkIcon } from "../Images/TimelineIcons/briefcase-svgrepo-com.svg";
import { ReactComponent as MedalIcon } from "../Images/TimelineIcons/medal-5-svgrepo-com.svg";
import { ReactComponent as SchoolIcon } from "../Images/TimelineIcons/graduation-cap-svgrepo-com.svg";

export default function Experience() {
  return (
    <div className="experience-container" name="experience">
      <h2 className="experience-header">Experience and Certifications</h2>

      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          date="2022 - present"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Front-End Web Developer
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Freelance</h4>
          <p>
            Working with React, HTML, CSS, and JS to create a number of
            promotional responsive websites for local small businesses.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          className="vertical-timeline-element--work"
          date="2021 - 2022"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Senior Project Manager
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Evolving Web</h4>
          <p>
            Oversaw all stages of Drupal project builds - from pitches, to sales
            handoff, to active management. Concurrently acted as Account Manager
            for clients in the higher education and government sphere. This
            included upselling project bundles, troubleshooting service issues,
            and maintaining overall client satisfaction.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          className="vertical-timeline-element--work"
          date="2021"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<MedalIcon />}
        >
          <h3 className="vertical-timeline-element-title">PMP Certification</h3>
          <h4 className="vertical-timeline-element-subtitle">
            PMI Toronto Chapter
          </h4>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          className="vertical-timeline-element--work"
          date="2019 - 2021"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Project Manager</h3>
          <h4 className="vertical-timeline-element-subtitle">DAC Group</h4>
          <p>
            {" "}
            The main focus of the project work at DAC was devoted to creating
            true omnichannel experiences for future facing clients. PM
            responsibilities included budget creation and tracking, managing
            cross-functional teams to implement campaigns, and refining
            estimation processes.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          className="vertical-timeline-element--work"
          date="2015 - 2019"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">Project Manager</h3>
          <h4 className="vertical-timeline-element-subtitle">
            Saatchi & Saatchi
          </h4>
          <p className="saatchi">
            <br></br>
            Responsible for web activity for a large auto manufacturer,
            including monthly offers and promotions, annual vehicle content
            changeovers, and back-end web service based projects. Lead
            activities for quoting on and budgeting projects throughout
            lifecycle, including reconciliation of estimate and actuals in order
            to improve team estimates for future projects.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          contentStyle={{ background: "#1d324f", color: "#fff" }}
          contentArrowStyle={{ borderRight: "7px solid  #1d324f" }}
          className="vertical-timeline-element--education"
          date="2008 - 2014"
          iconStyle={{ background: "#1d324f", color: "#fff" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            IT Project Management Certificate
          </h3>
          <h4 className="vertical-timeline-element-subtitle seneca">
            Seneca College
          </h4>
          <h3 className="vertical-timeline-element-title">
            Bachelor of Business Administration, Hon
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Brock University
          </h4>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}
