import "./Styles/HomePage.scss";
import Nav from "./Components/Nav";
import Hero from "./Components/Hero";
import About from "./Components/About";
import Work from "./Components/Work";
import Experience from "./Components/Experience";
import Contact from "./Components/Contact";
import ScrollToTop from "react-scroll-to-top";
import Favicon from "react-favicon";

export default function HomePage() {
  return (
    <div className="body-content">
      <Favicon url="https://img.icons8.com/?size=512&id=EAuEznFKwj8L&format=png"></Favicon>
      <Nav />
      <Hero />
      <About />
      <Work />
      <Experience />
      <Contact />
      <div className="scroll">
        <ScrollToTop smooth color="#ffffff" />
      </div>
    </div>
  );
}
