import "../Styles/Nav.scss";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";

export default function Nav() {
  const [isVisible, setIsVisible] = useState(false);

  const [show, setShow] = useState(false);

  const navbarControl = () => {
    if (window.scrollY < 100) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    navbarControl(); // Set initial value on page load
    window.addEventListener("scroll", navbarControl);
    return () => window.removeEventListener("scroll", navbarControl);
  }, []);

  function handleBurgerClick() {
    setIsVisible((prev) => !prev);
  }

  function handleListClick() {
    setIsVisible(false);
  }

  function handleMouseEnter(e) {
    e.target.classList.add("hovered");
  }

  function handleMouseLeave(e) {
    e.target.classList.remove("hovered");
  }

  return (
    <div
      className={`nav-container ${show ? " " : "hidden"} ${
        isVisible ? "expanded" : ""
      }`}
    >
      <div className="mobile-items">
        <div className="logo">
          <a href="#hero">L | A</a>
        </div>
        <div className="burger-container">
          <a
            href="#"
            className={`burger ${isVisible ? "burger-icon open" : ""}`}
            onClick={handleBurgerClick}
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
      </div>
      <div className={`navbar-links ${isVisible ? "visible" : ""}`}>
        <ul className="sections">
          <li>
            <Link
              onClick={handleListClick}
              className="menu-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              activeClass="active"
              to="About"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              onClick={handleListClick}
              className="menu-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              activeClass="active"
              to="Projects"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              onClick={handleListClick}
              className="menu-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              activeClass="active"
              to="experience"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Experience
            </Link>
          </li>
          <li>
            <Link
              onClick={handleListClick}
              className="menu-item"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              activeClass="active"
              to="Contact"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
