import "../Styles/Hero.scss";

export default function Hero() {
  return (
    <div className="hero-container" id="hero">
      <div className="hero-text">
        <h1>Laura Ariganello</h1>
        <p>Front-End Developer</p>
      </div>
    </div>
  );
}
