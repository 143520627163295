import "../Styles/Contact.scss";
import GMAIL from "../Images/ContactIcons/icons8-gmail-logo-96.png";
import GITHUB from "../Images/ContactIcons/icons8-github-96.png";
import LINKEDIN from "../Images/ContactIcons/icons8-linkedin-96.png";
import ARROW1 from "../Images/arrow.png";
import ARROW2 from "../Images/arrow2.png";

export default function Contact() {
  return (
    <div className="contact-container" name="Contact">
      <div className="content-container">
        <h2 className="section-title">Let's Connect</h2>
        <div className="icon-container">
          <a
            href="https://www.linkedin.com/in/laura-ariganello/"
            target="_blank"
            className="icon-link"
          >
            <img src={LINKEDIN} alt="linkedin logo" className="icon"></img>
          </a>

          <a
            href="https://github.com/lmrigs"
            target="_blank"
            className="icon-link"
          >
            <img src={GITHUB} alt="github logo" className="icon"></img>
          </a>

          <a href="mailto:lauramariganello@gmail.com" className="icon-link">
            <img src={GMAIL} alt="gmail logo" className="icon"></img>
          </a>
        </div>
        <img className="arrow1" src={ARROW1}></img>
        <img className="arrow2" src={ARROW2}></img>
      </div>
    </div>
  );
}
