import "../Styles/About.scss";
import Avatar from "../Images/Avatar.png";
import { ReactComponent as HTML } from "../Images/SkillsIcons/html5.svg";
import { ReactComponent as CSS } from "../Images/SkillsIcons/css3-alt.svg";
import { ReactComponent as JS } from "../Images/SkillsIcons/javascript-plain.svg";
import { ReactComponent as REACT } from "../Images/SkillsIcons/react-original.svg";
import { ReactComponent as SASS } from "../Images/SkillsIcons/sass-original.svg";
import { ReactComponent as GIT } from "../Images/SkillsIcons/github-original.svg";
import { ReactComponent as FIGMA } from "../Images/SkillsIcons/figma-plain.svg";
import { ReactComponent as NODE } from "../Images/SkillsIcons/nodejs-plain.svg";
import { ReactComponent as UBUNTU } from "../Images/SkillsIcons/ubuntu-plain.svg";
import { ReactComponent as VSCODE } from "../Images/SkillsIcons/vscode-plain.svg";
import { ReactComponent as PHP } from "../Images/SkillsIcons/php-plain.svg";
import { ReactComponent as MAGENTO } from "../Images/SkillsIcons/adobe-commerce.svg";
import { ReactComponent as SQL } from "../Images/SkillsIcons/mysql-logo.svg";

export default function About() {
  return (
    <div className="about-container" name="About">
      <div className="bio-container">
        <img src={Avatar} alt="avatar" className="avatar"></img>
        <p className="bio-text">
          After 9 years as a PMP Certified Digital Project Manager I craved a
          new challenge and immersed myself in studying web development. I found
          a passion for creating responsive and immersive web projects using
          HTML, CSS, JavaScript, and React. My extensive project management
          experience, technical expertise, and web development skills allow me
          to bring a unique and holistic perspective to digital projects. Let's
          connect and explore how I can add value to your team.
        </p>
      </div>
      <div className="skills-container">
        <div className="languages">
          <h3>Languages & Libraries</h3>
          <div className="logos">
            <div className="html">
              <HTML />
              <p>HTML5</p>
            </div>
            <div className="css">
              <CSS />
              <p>css3</p>
            </div>
            <div className="javascript">
              <JS />
              <p>JavaScript</p>
            </div>
            <div className="react">
              <REACT />
              <p>React.JS</p>
            </div>
            <div className="sass">
              <SASS />
              <p>SASS</p>
            </div>
          </div>
        </div>
        <div className="tech-learning">
          <div className="tech-tools">
            <h3>Tech & Tools</h3>
            <div className="logos">
              <div className="git">
                <GIT />
                <p>Github</p>
              </div>
              <div className="figma">
                <FIGMA />
                <p>Figma</p>
              </div>
              <div className="node">
                <NODE />
                <p>Node.js</p>
              </div>
              <div className="ubuntu">
                <UBUNTU />
                <p>Ubuntu</p>
              </div>
              <div className="vscode">
                <VSCODE />
                <p>VSCode</p>
              </div>
            </div>
          </div>
          <div className="learning">
            <h3>Learning...</h3>
            <div className="logos">
              <div className="logophp">
                <PHP className="php" />
                <p>PHP</p>
              </div>
              <div className="logosql">
                <SQL className="sql" />
                <p>MySQL</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
