import React from "react";
import Nav_Etsy from "../EtsyPage/Components/Nav_Etsy";
import Hero_Etsy from "../EtsyPage/Components/Hero_Etsy";
import Products_Etsy from "../EtsyPage/Components/Products_Etsy";
import About_Etsy from "../EtsyPage/Components/About_Etsy";
import Contact_Etsy from "../EtsyPage/Components/Contact_Etsy";
import Footer_Etsy from "../EtsyPage/Components/Footer_Etsy";
import "../EtsyPage/Styles/EtsyPage.scss";

function EtsyPage() {
  return (
    <div className="etsy-body-content">
      <Nav_Etsy />
      <Hero_Etsy />
      <Products_Etsy />
      <About_Etsy />
      <Contact_Etsy />
      <Footer_Etsy />
    </div>
  );
}

export default EtsyPage;
